import Login from 'components/pages/Login/Login';
import { buildPage, buildStaticProps } from 'utils/pageHelper';

export const getServerSideProps = buildStaticProps('index');
export default buildPage(Login, {
  fullPage: true,
  skipSSR: true,
  metaData: {
    title: 'Welcome',
    description: 'Please log in or sign up',
  },
  loggedInRedirect: '/dashboard',
});
